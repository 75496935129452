import { Box, Typography } from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'

const NotFound = () =>
    <Box sx={{ height: '100vh', width: '100vw', backgroundColor: '#1C2440', color: 'whitesmoke' }} display="flex"
        justifyContent="center"
        alignItems="center">
        <h3>找不到您要求的頁面</h3>
        <Link to="/" sx={{ color: 'whitesmoke'}}>
            <Typography variant='paperName'>點此回到首頁</Typography>
        </Link>
    </Box>

export default NotFound