import * as React from 'react';
import { Typography, Box, Stack, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';



export default function Footer() {
    return (
        <Box display="flex" alignItems="center" sx={{ backgroundColor: '#fdfdfd' }}>
            <Stack>
                <Grid container>
                    <Grid item>
                        <Stack>
                            <Typography variant='note' sx={{ ml: 3, fontWeight: 'bold' }}>
                                海福商務飯店
                            </Typography>
                            <Typography variant='note' sx={{ ml: 3 }}>
                                統編 : 92980699
                            </Typography>
                            <Typography variant='note' sx={{ ml: 3 }}>
                                旅館登記字號 : 金門縣旅館014號
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack>
                            <Typography variant='note' sx={{ ml: 3, fontWeight: 'bold' }}>
                                海福花園飯店
                            </Typography>
                            <Typography variant='note' sx={{ ml: 3 }}>
                                統編 : 53011028
                            </Typography>
                            <Typography variant='note' sx={{ ml: 3 }}>
                                旅館登記字號 : 金門縣旅館031號
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Typography variant='note' sx={{ ml: 3, mt: 1 }}>© 金門海福飯店 2022 All Rights Reserved.</Typography>
            </Stack>
        </Box>
    );
}
