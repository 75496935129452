import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import NotFound from './pages/404'
import Hotel from './pages/hotel';
import Landing from './pages/landing';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { ParallaxProvider } from 'react-scroll-parallax';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "animate.css/animate.min.css";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import News from './pages/news';

const theme = createTheme({
  // status: {
  //   danger: orange[500],
  // },
  palette: {
    primary: {
      // main: '#E4C384'
      main: '#141B3E'
    },
    func: {
      main: '#E4C384'
    },
    garden: {
      main: '#E4C384',
      contrastText: '#fff',
    },
  },
});

theme.typography = {
  slogan: {
    color: '#f0f8ff',
    fontSize: '2rem',
    textAlign: 'center',
    fontWeight: 500,
    letterSpacing: '0.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '4rem'
    }
  },
  hotelName: {
    color: '#a3a3a3',
    fontSize: '2rem',
    textAlign: 'center',
    fontWeight: 400,
    letterSpacing: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '5rem'
    }
  },
  hotelGardenName: {
    color: '#f0f8ff',
    fontSize: '2rem',
    textAlign: 'center',
    fontWeight: 400,
    letterSpacing: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '5rem'
    }
  },
  landingSlogan: {
    color: '#f0f8ff',
    fontSize: '1.1rem',
    textAlign: 'center',
    fontWeight: 300,
    letterSpacing: '0.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    }
  },
  landingBgStroke: {
    fontWeight: 400,
    fontSize: '3rem',
    textAlign: 'center',
    letterSpacing: '0.12em',
    color: 'transparent',
    '-webkit-text-stroke-width': '0.3px',
    '-webkit-text-stroke-color': '#FFF',
    [theme.breakpoints.up('md')]: {
      fontSize: '8rem'
    }
  },
  landingGarden: {
    color: '#1C2440',
    fontSize: '1rem',
    textAlign: 'right',
    fontWeight: 500,
    letterSpacing: '0.2rem',
  },
  landingBusiness: {
    color: '#D7A253',
    fontSize: '1rem',
    fontWeight: 500,
    letterSpacing: '0.2rem',
  },
  paperName: {
    fontWeight: 500,
    fontSize: '1rem',
    // lineHeight: '29px',
    letterSpacing: '0.12em'
  },
  paperDes: {
    fontWeight: 500,
    fontSize: '0.9rem',
    letterSpacing: '0.12em'
  },
  serviceTitle: {
    fontWeight: 500,
    fontSize: '1.1rem',
    letterSpacing: '0.12em'
  },
  serviceDes: {
    // fontWeight: 500,
    fontSize: '0.9rem',
    letterSpacing: '0.12em'
  },
  note: {
    fontSize: '0.8rem',
    letterSpacing: '0.12em',
    color: '#787878'
  },
  philosophy: {
    fontWeight: 400,
    fontSize: '1.2rem',
    letterSpacing: '0.12em',
    color: '#f0f8ff'
  },
  newsTitle: {
    fontSize: '1.5rem'
  }, 
  slideSlogan: {
    fontSize: '1.8rem',
    fontWeight: 500,
    color: '#ECECEC',
    textShadow: '-23px -18px 4px rgba(98, 98, 98, 0.68)',
    letterSpacing: '0.43rem',
    [theme.breakpoints.up('md')]: {
      letterSpacing: '2rem',
      fontSize: '5rem'
    },
  },
  slideText: {
    fontSize: '1.5rem',
    fontWeight: 400,
    letterSpacing: '0.43rem',
    color: '#ECECEC',
    lineHeight: '1rem'
  }
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ParallaxProvider>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/business" element={<Hotel whichHotel='business'/>} />
          <Route path="/garden" element={<Hotel whichHotel='garden'/>} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/news/:id" element={<News />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </ParallaxProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
