import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from 'prop-types';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InstagramOutlinedIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import Line from './LINE_Brand_icon.png'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import zIndex from '@mui/material/styles/zIndex';

const style = (theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    [theme.breakpoints.up('md')]: {
        width: 400,
    },
    bgcolor: 'background.paper',
    // border: '0px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    p: 1,
});

const buttonStyle = {
    position: 'fixed',
    right: 20,
    bottom: 20,
    color: '#E4C384',
    zIndex: 300
}

const iconStyle = {
    borderRadius: '50%',
    border: '1px solid',
    padding: '5px'
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    alignItems: 'center',
    display: 'flex'
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Stack spacing={1}>
                    <a href={"tel:" + children.phone} target="_blank" >
                        <Item>
                            <LocalPhoneOutlinedIcon sx={{ mr: 1 }} />
                            <span
                            >{children.phone}</span>
                            {/* <ContentCopyOutlinedIcon sx={{textAlign: 'right'}} onClick={() => { navigator.clipboard.writeText(children.phone) }} /> */}
                        </Item>
                    </a>
                    <a href={`https://www.facebook.com/${children.fb}/`} target="_blank">
                        <Item>
                            <FacebookOutlinedIcon sx={{ mr: 1 }} />
                            <span
                            >{children.fb}</span>
                        </Item>
                    </a>
                    <a href={`https://www.instagram.com/${children.ig}/`} target="_blank">
                        <Item>
                            <InstagramOutlinedIcon sx={{ mr: 1 }} />
                            <span
                            >{children.ig}</span>
                        </Item>
                    </a>
                    <a href={children.line_link} target="_blank">
                        <Item>
                            <img src={Line} style={{
                                width: '20px', paddingRight: '10px', filter: 'grayscale(100%)'
                            }} /> {children.line}
                        </Item>
                    </a>
                    <a href={children.map} target="_blank">
                        <Item>
                            <LocationOnOutlinedIcon sx={{ mr: 1 }} /> <span>{children.address}</span>
                        </Item>
                    </a>
                    <a href={'mailto:' + children.email} target="_blank">
                        <Item>
                            <EmailOutlinedIcon sx={{ mr: 1 }} /> {children.email}
                        </Item>
                    </a>
                    <Item>
                        <FaxOutlinedIcon sx={{ mr: 1 }} /> {children.fax}
                    </Item>
                </Stack>)}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ContactModal() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div>
            <IconButton sx={buttonStyle} onClick={handleOpen}><MessageRoundedIcon sx={iconStyle} /></IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Tabs value={value}
                        onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="海福商務飯店" {...a11yProps(0)} />
                        <Tab label="海福花園飯店" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        {{
                            name: '海福商務飯店',
                            phone: '082-322538',
                            map: 'https://goo.gl/maps/dyz1prv7kA3XaV2eA',
                            address: '893 金門縣金城鎮民權路85號',
                            email: 'haifu.kinmenhotel@gmail.com',
                            fax: '082-322539',
                            line: 'haifu92980699',
                            line_link: 'https://lin.ee/3vU1RdGI3',
                            fb: 'haifu.hotel',
                            ig: 'haifuhotel'
                        }}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {{
                            name: '海福花園飯店',
                            phone: '082-353888',
                            map: 'https://goo.gl/maps/SpRFKKUybDK2',
                            address: '890 金門縣金沙鎮高陽路1號',
                            email: 'lh1688.tk999@msa.hinet.net',
                            fax: '082-353456',
                            line: '082353888',
                            line_link: '',
                            fb: 'haifu.hotel',
                            ig: 'haifuhotel'
                        }}
                    </TabPanel>
                </Box>
            </Modal>
        </div>
    );
}
