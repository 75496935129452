import React from 'react';
import { Button, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import Cookies from 'js-cookie'

export default function CookieDialog() {
    const [open, setOpen] = React.useState(Cookies.get('use-cookie') === undefined ? true : !Cookies.get('use-cookie'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseAccept = () => {
        Cookies.set("use-cookie", true)
        setOpen(false);
    }

    const handleCloseNotAccept = () => {
        Cookies.set("use-cookie", false)
        setOpen(false);

    }

    const theme = useTheme();
    return (
        <div>
            <Snackbar open={open}
                onClose={() => { }}>
                <Alert severity="info" variant="filled">
                    我們使用Cookie以使網頁正常運作並增進瀏覽體驗。詳細政策請點此。
                    <Button onClick={handleCloseAccept}>同意</Button>
                    <Button onClick={handleCloseNotAccept}>不同意</Button>
                </Alert>
            </Snackbar>
        </div>
    );
}