import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Typography, Box, Grid, Button } from '@mui/material';

import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';

import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';


import { useNavigate } from 'react-router-dom';

import Logo from './logo.png';
import bLogo from './business_logo.png'
import gLogo from './garden_logo.png'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const styles = {
    // this group of buttons will be aligned to the right side
    toolbarButtons: {
        marginLeft: 'auto',
    },
};



export default function NavBar(props) {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [drawer, setDrawer] = React.useState(false);
    const [overColor, setOverColor] = React.useState(false);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const scrollOverColor = () => {
        if (window.scrollY > 600) {
            setOverColor(true);
        } else {
            setOverColor(false);
        }
    }

    window.addEventListener("scroll", scrollOverColor)

    const handleDrawerOpen = () => {
        setDrawer(true);
    }

    const handleDrawerClose = () => {
        setDrawer(false);
    }

    return (
        // <Box sx={{ flexGrow: 1, overflowX: 'hidden'}} container alignItems='space-between'>
        <AppBar position="fixed" sx={{ boxShadow: 0, backgroundColor: overColor ? '#fdfdfd' : props.mainColor, overflow: 'hidden' }}>
            <Toolbar sx={{ display: 'flex', justifyContent: "space-between", width: '100%' }}>
                <Drawer
                    anchor='top'
                    open={drawer}
                    onClose={handleDrawerClose}
                >
                    <Box sx={{ width: '90vw' }}>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            {/* <ListItemButton>
                                <ListItemIcon>
                                    <SendIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sent mail" />
                            </ListItemButton> */}
                            {props.links.map(l => (
                                <ListItemButton key={`nav-${l.title}`} item sx={{ cursor: 'pointer' }} onClick={() => l.url == '' ? l.scrollCall() : navigate(l.url)}>
                                    <Typography >{l.title}</Typography>
                                </ListItemButton>
                            ))}
                        </List>
                    </Box>
                </Drawer>

                <Box sx={{display: 'flex', flexGrow: 1}}>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <Button
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleDrawerOpen}
                            color="inherit"
                        >
                            <MenuIcon sx={{ color: overColor ? props.mainColor : null }} />
                        </Button>
                    </Box>


                    <Box
                        item
                        component="img"
                        sx={{
                            height: 50,
                            my: 2,
                            filter: overColor ? "" : "brightness(0) invert(1)",
                            display: 'flex',
                        }}
                        alt="Haifu"
                        src={props.whichHotel == 'garden' ? gLogo : bLogo}
                    />

                </Box>

                <Box>
                    <Grid container sx={{ mr: 4 }}>
                        {props.links.map(l => (
                            <Grid key={`nav-${l.title}`} item sx={{ mx: 3, cursor: 'pointer', display: { xs: 'none', md: 'flex' } }}>
                                <Typography
                                    sx={{
                                        color: overColor ? props.mainColor : ""    
                                    }}
                                    onClick={() => l.url == '' ? l.scrollCall() : navigate(l.url)}>
                                    {l.title}
                                </Typography>
                            </Grid>
                        ))}
                        {props.orderUrl &&
                            <Grid item sx={{ mx: 3, cursor: 'pointer' }}>
                                <a href={props.orderUrl} target='_blank'>
                                    <Button sx={{ borderRadius: 12.5, backgroundColor: props.highlightColor }} variant="contained">線上預訂</Button>
                                </a>
                            </Grid>}
                    </Grid>
                </Box>
                {/* <Box>
                    {
                        props.links.map(l => (
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                            >
                                {l.title}
                            </Typography>
                        ))
                    }
                </Box> */}
                {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                    </Search> */}
            </Toolbar>
        </AppBar>
        // </Box>
    );
}
