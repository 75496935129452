import ContactModal from '../components/contact';
import { Typography, Box, Container } from '@mui/material';
import CookieDialog from '../components/CookieDialog';
import NavBar from "../components/navbar";
import './hotel.css'
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack'
import Footer from '../components/Footer';

function News() {

    let { id } = useParams();
    let [news, setNews] = useState({});

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/news/" + id)
            .then(response => response.json())
            // 4. Setting *dogImage* to the image url that we received from the response above
            .then(result => { setNews(result) })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state })
            })
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className='container'>
            <NavBar links={[{ title: '首頁', url: '/landing' }, { title: '商務飯店', url: '/business' },
            { title: '花園飯店', url: '/garden' }]} />
            <ContactModal />
            <CookieDialog />

            <Container maxWidth="lg">
                {/* <Box sx={{ bgcolor: '#cfe8fc', height: '100vh' }} /> */}
                <Stack sx={{ mt: { xs: 15, md: 10 }, pb: 10 }}>
                    {news.Image &&
                        <Box component="img"
                            // src='https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300'
                            src={news.Image}
                            sx={{ width: '100%', }}
                        ></Box>}
                    <Typography sx={{ mt: 3 }} variant='newsTitle'>{news.Title}</Typography>
                    <Typography sx={{ mb: 3 }} variant='note'>{new Date(news.Timestamp).toDateString()}</Typography>
                    <div dangerouslySetInnerHTML={{ __html: news.Content }} />
                </Stack>

            </Container>
            <Footer />
        </div>
    );
}

export default News;
