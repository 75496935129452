import Grid from '@mui/material/Grid';
import ContactModal from '../components/contact';
import { Typography, Box, Button } from '@mui/material';
import NavBar from "../components/navbar";
import './hotel.css'
import Paper from '@mui/material/Paper';
import React, { createRef } from 'react';
import Stack from '@mui/material/Stack'
import { Parallax } from 'react-scroll-parallax'
import { Navigate, Link } from "react-router-dom"
import CookieDialog from '../components/CookieDialog';
import ButtonModalEmbed from '../components/ButtonModalEmbed';

var ReactRotatingText = require('react-rotating-text');

import { Zoom, Fade } from 'react-slideshow-image';
import useDraggableScroll from 'use-draggable-scroll';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import Footer from '../components/Footer';
import Newsletter from '../components/newsletter';

const hotels = {
    business: {
        roomAPI: '/business_room',
        newsAPI: '/business_news',
        orderUrl: 'https://www.booking-wise0.com.tw/haifu/hotel/reservation/reservation.php?hid=101',
        mainColor: '#1C2440',
        highlightColor: '#D7A253',
        class: 'business-hotel-session',
        service1: 'image/business_service_1.webp',
        service2: 'image/business_service_2.webp',
        slides: [
            {
                src: 'image/suite_slide/slide1.jpg',
                slogan1: '現代且時尚',
                slogan2: '的住宿選擇',
                sloganText: '免費早餐與迎賓飲料'
            },
            {
                src: 'image/suite_slide/slide2.jpg',
                slogan1: '位於金門市區',
                slogan2: '絕佳地理位置',
                sloganText: '提供機場碼頭接送服務'
            }
        ]
    },
    garden: {
        roomAPI: '/garden_room',
        newsAPI: '/garden_news',
        orderUrl: 'https://www.booking-wise0.com.tw/haifu/hotel/reservation/reservation.php?hid=102',
        mainColor: '#D7A253',
        highlightColor: '#1C2440',
        class: 'garden-hotel-session',
        service1: 'image/garden_service_1.webp',
        service2: 'image/garden_service_2.webp',
        slides: [
            {
                src: 'image/garden_slide/slide1.jpg',
                slogan1: '現代且時尚',
                slogan2: '的住宿選擇',
                sloganText: '免費早餐與迎賓飲料'
            },
            {
                src: 'image/garden_slide/slide2.jpg',
                slogan1: '位於金門市區',
                slogan2: '絕佳地理位置',
                sloganText: '提供機場碼頭接送服務'
            }
        ]
    }
}

const slideBase = {
    pauseOnHover: false,
    arrows: false,
}

const zoomInProperties = {
    ...slideBase,
    scale: 1.2
}

const zoomInPropertiesSlogan = {
    ...slideBase,
    transitionDuration: 400,
    // duration: 5500,
    // cssClass: 'slogan'
}

const slogan1Properties = {
    ...zoomInPropertiesSlogan,
    duration: 5500
}
const slogan2Properties = {
    ...zoomInPropertiesSlogan,
    duration: 5700
}
const textProperties = {
    ...zoomInPropertiesSlogan,
    duration: 5900
}

class Hotel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isFetching: true, rooms: [], news: [], setting: hotels[props.whichHotel], goNews: false };
        this.ref = createRef(null);
        this.newsRef = createRef(null);
        this.serviceRef = createRef(null);
        this.roomRef = createRef(null);
        this.onMouseDown = useDraggableScroll(this.ref);
        this.scrollToNews = this.scrollToNews.bind(this)
        this.scrollToService = this.scrollToService.bind(this)
        this.scrollToRoom = this.scrollToRoom.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({ isFetching: true })
        fetch(process.env.REACT_APP_API_URL + this.state.setting.roomAPI, {
            crossDomain: true,
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                let sortedRoom = [...result];
                sortedRoom.sort((a, b) => a.DisplayOrder > b.DisplayOrder);
                this.setState({ rooms: [...sortedRoom], isFetching: false });
            })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state, isFetching: false });
            });

        fetch(process.env.REACT_APP_API_URL + this.state.setting.newsAPI, {
            crossDomain: true,
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                let sortedNews = [...result];
                sortedNews.sort((a, b) => new Date(b.Timestamp).getTime() - new Date(a.Timestamp).getTime());
                this.setState({ news: [...sortedNews] });
            })
            .catch(e => {
                console.log(e);
                this.setState({ ...this.state });
            });
    }

    scrollToNews() {
        this.newsRef.current.scrollIntoView()
    }
    scrollToService() {
        this.serviceRef.current.scrollIntoView()
    }
    scrollToRoom() {
        this.roomRef.current.scrollIntoView()
    }

    render() {
        return (
            <div className='container'>
                <NavBar whichHotel={this.props.whichHotel}
                    links={[{ title: '首頁', url: '/' }, { title: '最新消息', url: '', scrollCall: this.scrollToNews },
                    { title: '服務介紹', url: '', scrollCall: this.scrollToService },
                    { title: '房型介紹', url: '', scrollCall: this.scrollToRoom },]}
                    orderUrl={this.state.setting.orderUrl}
                    mainColor={this.state.setting.mainColor} highlightColor={this.state.setting.highlightColor} />
                <ContactModal />
                <CookieDialog />
                <ButtonModalEmbed />
                {
                    <Newsletter is_hotel_page={true} />
                }
                {/* First section: animation and landing */}
                <div className={this.state.setting.class}>
                    <div className='slide-container'>
                        <Zoom {...zoomInProperties}>
                            {this.state.setting.slides.map((each, index) => (
                                <div key={index}>
                                    <img className="slide" src={`${process.env.PUBLIC_URL}/${each.src}`} />
                                </div>
                            ))}
                        </Zoom>
                        <div className='slide-filter'></div>
                    </div>

                    <div className='slogan'>
                        <Fade {...slogan1Properties}>
                            {this.state.setting.slides.map((each, index) => (
                                <div key={index + '-typo'}>
                                    <Typography variant='slideSlogan' className='slide-slogan-1'>{each.slogan1}</Typography>
                                    <Typography variant='slideSlogan' className='slide-slogan-2'>{each.slogan2}</Typography>
                                </div>
                            ))}
                        </Fade>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} className='slide-text'>
                            <ReactRotatingText items={this.state.setting.slides.map((each, index) => (each.sloganText))} pause={4000} />
                        </Box>
                    </div>
                </div>

                <div className='news-session' ref={this.newsRef}>
                    <Grid container sx={{ pt: 10, ml: 4 }}>
                        <AnimationOnScroll animateIn="animate__fadeIn">
                            <Grid>
                                <div className='headline-div'></div>
                            </Grid>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                            <Grid item xs={11} sx={{ ml: 1 }}>
                                最新消息
                            </Grid>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn='animate__fadeInUp' animateOnce>
                            <Grid container justifyContent="start" spacing={2} sx={{ mt: 3 }}>
                                {this.state.news.map((news, i) => (
                                    <Grid key={'news-' + i} item>
                                        {/* {this.state.goNews && <Navigate to={`/news/${news.ID}`}/>} */}
                                        <Link to={`/news/${news.ID}`} style={{ textDecoration: 'none' }}>
                                            <Paper
                                                sx={{
                                                    height: 400,
                                                    width: 350,
                                                    cursor: 'pointer',
                                                    backgroundColor: (theme) =>
                                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                                }}
                                            >{
                                                    news.Image &&
                                                    <Box component="img"
                                                        src={news.Image}
                                                        sx={{ height: '50%', maxWidth: '100%', textAlign: 'center' }}
                                                        m="auto"
                                                    ></Box>}
                                                <Stack sx={{ ml: 2 }} direction="column"
                                                    justifyContent="space-between">
                                                    <Typography sx={{ mt: 2 }} variant="paperName">{news.Title}</Typography>
                                                    <Typography variant='note'>{new Date(news.Timestamp).toDateString()}</Typography>
                                                    <Typography variant="paperDes" sx={{ mt: 3 }}>{news.Brief}...</Typography>
                                                    <Typography sx={{ textAlign: 'right', mr: 1, mt: 1 }} variant='note'>閱讀更多</Typography>

                                                </Stack>
                                            </Paper>
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                        </AnimationOnScroll>
                    </Grid>
                </div>

                <div className='news-session' ref={this.serviceRef}>
                    <Grid container direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        sx={{ pt: 2 }}>
                        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                            <Parallax speed={20}>
                                <Stack justifyContent="center"
                                    alignItems="center">
                                    <Box sx={{ mb: 5 }}>
                                        <img className="service-img" src={`${process.env.PUBLIC_URL}/${this.state.setting.service1}`} />
                                    </Box>
                                    <Box>
                                        <img className="service-img" src={`${process.env.PUBLIC_URL}/${this.state.setting.service2}`} />
                                    </Box>
                                </Stack>
                            </Parallax>
                        </Grid>
                        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                            <Parallax speed={-10}>
                                <Box sx={{ mx: 6 }}>
                                    <Typography variant="serviceTitle" sx={{ mr: 2 }}>貼心服務</Typography>
                                    <br />
                                    <Typography variant="serviceDes">機場接送。
                                        航班資訊。
                                        郵件寄送。
                                        行李寄存。
                                        租車服務。
                                        旅遊資訊。
                                        地陪服務。
                                        代購特產。
                                        喚醒服務。
                                        飯店叫車。</Typography>
                                    <br /><br />
                                    <Typography variant="serviceTitle" sx={{ mr: 2 }}>飯店設施</Typography>
                                    <br />
                                    <Typography variant="serviceDes">* 旅店設施24小時前檯接待：影印及傳真服務 / 旅遊及交通諮詢大廳資訊區：提供電腦、附設免費咖啡和高粱醋、茶飲
                                        <br />
                                        * 全館附設免費無線網路Wi-Fi
                                        <br />
                                        * B1 餐廳早晨七時至九時三十分，在充滿音樂的空間中優雅自在地享用為您準備的中西式自助早餐。
                                        <br />
                                        * 其他備品熨斗與燙衣板、轉接插頭，嬰兒備品（如有需要請洽櫃檯人員）。
                                        <br />
                                        * 免費汽、機車停車場車輛可提供住宿期間依抵達順序停車，恕無法預訂保留。
                                    </Typography>
                                </Box>
                            </Parallax>
                        </Grid>
                    </Grid>
                </div>

                <div className='news-session' ref={this.roomRef}>
                    <Grid container sx={{ pt: 15 }}>
                        <Grid sx={{ ml: 4 }}>
                            <div className='headline-div'></div>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                            房型介紹
                        </Grid>
                        <Stack direction="row" spacing={2} sx={{ py: 3, maxWidth: '100vw', overflowX: 'scroll', overflowY: 'hidden', cursor: 'grab' }} ref={this.ref} onMouseDown={this.onMouseDown.onMouseDown}>
                            {this.state.rooms.map((room, i) => (
                                <Paper key={i + '-' + 'room'} className="room-paper"
                                    component={Stack}
                                    direction="column"
                                    justifyContent="space-between"
                                    sx={{ ml: 4, height: 420, width: 350, backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1A2027' : '#fff', }}
                                >
                                    <Stack direction="column" justifyContent="space-between">
                                        <Box component="img"
                                            src={room.Image}
                                            sx={{ width: 350, }}
                                        ></Box>
                                        <Stack sx={{ ml: 2, mr: 2, }} direction="column">
                                            <Typography variant="paperName" sx={{ mt: 2 }}>{room.Name}</Typography>
                                            <Typography variant="paperDes" sx={{ mt: 3 }}>{room.Description}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack sx={{ ml: 2, mr: 2, mb: 3 }} direction="column" justifyContent="space-between">
                                        <Typography variant="note" sx={{ textAlign: 'right' }}>{'原價 NT$ ' + room.Price}</Typography>
                                        <Typography variant="note" sx={{ textAlign: 'right' }}>{'平日優惠 NT$ ' + room.Discount}</Typography>
                                    </Stack>
                                </Paper>
                            ))}
                        </Stack>
                        <Grid item sx={{ ml: 5, mt: 5 }} xs={12}>
                            <Stack direction="column">
                                <Typography variant="note">房價皆包含免費早餐,</Typography>
                                <Typography variant="note">例假日與國定假日適用原價</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Hotel;
