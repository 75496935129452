import Grid from '@mui/material/Grid';
import { Stack, Typography, Box, Button } from '@mui/material';
import './landing.css'
import GoogleMapReact from 'google-map-react';
import { Parallax } from 'react-scroll-parallax'
import { useEffect, useLayoutEffect } from 'react';
import CircleType from 'circletype';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Marquee from "react-fast-marquee";
import ContactModal from '../components/contact';
import Newsletter from '../components/newsletter'
import CookieDialog from '../components/CookieDialog';
import Footer from '../components/Footer';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

import { useNavigate } from 'react-router-dom';


function Landing() {
    const navigate = useNavigate();

    const defaultProps = {
        center: {
            lng: 118.3150132,
            lat: 24.4334242
        },
        gardenCenter: {
            lng: 118.3879132,
            lat: 24.4666963,
        },
        zoom: 15
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    useEffect(() => {
        const circleRef = document.querySelector('#circularText');
        const rotate = new CircleType(circleRef).radius(20)

        window.addEventListener("scroll", function () {
            circleRef.style.transform = `rotate(${window.scrollY * 0.15}deg)`
        })

    }, []);


    return (
        <div className='container'>
            {/* <NavBar links={[{ title: '海福商務飯店', url: '/business' }, { title: '海福花園飯店', url: '/garden' }]} /> */}
            <ContactModal />
            <Newsletter />
            <CookieDialog />

            <div className="circular-text">
                <p className="text" id='circularText' >HAIFU • HOTEL • HAIFU • HOTEL •</p>
            </div>
            <Box className='business' sx={{ minHeight: { md: '100vh' } }}>
                <Box sx={{ pt: 8 }} className='stroke-text'>
                    <Typography variant="landingBgStroke">HAIFU HOTEL</Typography>
                </Box>
                <Box className='stroke-text'>
                    <Typography variant="landingBgStroke">HAIFU HOTEL</Typography>
                </Box>
                <Box className='stroke-text'>
                    <Typography variant="landingBgStroke">HAIFU HOTEL</Typography>
                </Box>
                <div className='landing-container'>
                    {/* This absolute div causes the content excceed parant and overlaps the below section. */}
                    <Grid container rowSpacing={{ xs: 3, lg: 0 }} justifyContent="center">
                        <Grid item xs={12} md={4} lg={4} xl={3} sx={{ mt: 4, textAlign: 'center' }}>
                            <Parallax speed={-10}>
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={12}>
                                        <img className='square-img' src="image/business-square.webp" alt="" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='landingSlogan'>海福商務飯店</Typography>
                                    </Grid>
                                    <Grid item sx={{ cursor: 'pointer' }} onClick={() => navigate('/business')}>
                                        <Typography variant='landingBusiness'>進入官網</Typography>
                                    </Grid>
                                    <Grid item sx={{ cursor: 'pointer', textDecoration: 'none' }}>
                                        <a href="https://www.booking-wise0.com.tw/haifu/hotel/reservation/reservation.php?hid=101" target='_blank'>
                                            <Typography variant='landingBusiness'>
                                                線上訂房
                                            </Typography>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Parallax>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} xl={3} sx={{ textAlign: 'center' }}>
                            {/* <Parallax speed={10}> */}
                            <Grid container spacing={1} justifyContent="center">
                                <Grid item xs={12}>
                                    <img className='square-img' src="image/garden-square.webp" alt="" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='landingSlogan'>海福花園飯店</Typography>
                                </Grid>
                                <Grid item sx={{ cursor: 'pointer' }} onClick={() => navigate('/garden')}>
                                    <Typography variant='landingBusiness'>進入官網</Typography>
                                </Grid>
                                <Grid item sx={{ cursor: 'pointer' }}>
                                    <a href="https://www.booking-wise0.com.tw/haifu/hotel/reservation/reservation.php?hid=102" target='_blank'>
                                        <Typography variant='landingBusiness'>
                                            線上訂房
                                        </Typography>
                                    </a>
                                </Grid>
                            </Grid>
                            {/* </Parallax> */}
                        </Grid>
                    </Grid>
                </div>
            </Box>

            <Box display="flex"
                justifyContent="center"
                alignItems="center"
                className='business' sx={{ minHeight: { md: '100vh' } }}>
                <Grid container direction="column"
                    justifyContent="center" alignItems="center">
                    <Grid item>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Typography variant="note">我們的經營理念</Typography>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Typography variant="philosophy">顛覆離島的住宿體驗</Typography>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Typography variant="philosophy">提供金門現代化而實惠的飯店選擇</Typography>
                        </AnimationOnScroll>
                    </Grid>
                </Grid>
            </Box>

            <Box className='business' sx={{ display: 'none', minHeight: { md: '20vh' } }}>
                <Grid container direction="row" spacing={10} justifyContent="center">
                    <Grid item>
                        <Parallax speed={-10}>
                            <Box sx={{}}>
                                <img className="landing-img" src={`${process.env.PUBLIC_URL}/image/landing/landing_1.jpg`} />
                            </Box>
                        </Parallax>
                    </Grid>
                    <Grid item>
                        <Parallax speed={-10}>
                            <Box sx={{}}>
                                <img className="landing-img" src={`${process.env.PUBLIC_URL}/image/landing/landing_2.jpg`} />
                            </Box>
                        </Parallax>
                    </Grid>
                    <Grid item>
                        <Parallax speed={-10}>
                            <Box sx={{}}>
                                <img className="landing-img" src={`${process.env.PUBLIC_URL}/image/landing/landing_3.jpg`} />
                            </Box>
                        </Parallax>
                    </Grid>
                    {/* <Grid item>
                                <Parallax speed={-10}>
                                    <Box sx={{}}>
                                        <img className="landing-img" src={`${process.env.PUBLIC_URL}/image/landing/landing_4.jpg`} />
                                    </Box>
                                </Parallax>
                            </Grid> */}
                    <Grid item>
                        <Parallax speed={-10}>
                            <Box sx={{}}>
                                <img className="landing-img" src={`${process.env.PUBLIC_URL}/image/landing/landing_5.jpg`} />
                            </Box>
                        </Parallax>

                    </Grid>
                </Grid>
            </Box>

            <Box className='business' sx={{ minHeight: { md: '100vh' } }}>
                <Grid container sx={{ py: 15, ml: 5 }}>
                    <Grid item sx={{ mb: 5 }}>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Marquee gradient={false} speed='80'>
                                <Typography variant="hotelName">HAIFU BUSINESS HOTEL • 海福商務飯店 • </Typography>
                            </Marquee>
                        </AnimationOnScroll>
                    </Grid>
                    {/* <Grid item sx={{ mx: 3 }} xs={12} md={6}>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <img src="image/haifu_hotel-min-long.jpg" className='hotel-img' alt="" />
                        </AnimationOnScroll>
                    </Grid> */}
                    <Grid item>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            {/* <Typography variant="landingBusiness">海福商務飯店</Typography> */}
                            {/* <div style={{ height: '40vh', width: '90vw' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyBKjkwOOnCjicw-yS8Mg9-NERB23OXL6VE", id: "df9dc46d4d8af819" }}
                                    defaultCenter={defaultProps.center}
                                    defaultZoom={defaultProps.zoom}
                                    MapOptions={{ mapId: "df9dc46d4d8af819" }}
                                >
                                    <AnyReactComponent
                                        lat={24.4334242}
                                        lng={118.3150132}
                                        text="海福商務飯店"
                                    />
                                </GoogleMapReact>
                            </div> */}
                            <div style={{ height: '40vh', width: '90vw' }} dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3632.500231586367!2d118.31282451544004!3d24.43342418425711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3414a26c28bcad8d%3A0x5ef2659a656af89e!2z5rW356aP5ZWG5YuZ6aOv5bqX!5e0!3m2!1szh-TW!2stw!4v1662961893750!5m2!1szh-TW!2stw" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' }} />
                        </AnimationOnScroll>
                    </Grid>
                    <Grid item xs={12} sx={{ mx: 3, mt: 2 }}>
                        <Stack direction="row" spacing={2}>
                            {/* <Stack direction="row" spacing={1} sx={{ px: 2, borderRadius: 12.5, backgroundColor: '141B3E', boxShadow: 2 }}>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay='50'>
                                    <ContactButton>
                                        <LocalPhoneOutlinedIcon sx={{ color: '#fff' }} />
                                    </ContactButton>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay='75'>
                                    <ContactButton>
                                        <FaxOutlinedIcon sx={{ color: '#fff' }} />
                                    </ContactButton>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay='100'>
                                    <ContactButton>
                                        <EmailOutlinedIcon sx={{ color: '#fff' }} />
                                    </ContactButton>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay='100'>
                                    <ContactButton>
                                        <FacebookIcon sx={{ color: '#fff' }} />
                                    </ContactButton>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay='100'>
                                    <ContactButton>
                                        <InstagramIcon sx={{ color: '#fff' }} />
                                    </ContactButton>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay='125'>
                                    <ContactButton>
                                        <img src={LineIcon} style={{
                                            width: '25px', height: 'auto', filter: 'grayscale(100%)'
                                        }} />
                                    </ContactButton>
                                </AnimationOnScroll>
                            </Stack> */}
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay='150'>
                                <Button sx={{ borderRadius: 12.5 }} variant="contained" onClick={() => navigate('/business')}> 進入官網 </Button>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay='175'>
                                <a href="https://www.booking-wise0.com.tw/haifu/hotel/reservation/reservation.php" target='_blank'>
                                    <Button sx={{ borderRadius: 12.5 }} variant="contained"> 線上訂房 </Button>
                                </a>
                            </AnimationOnScroll>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <div className='garden'>
                <Grid container sx={{ pt: 2 }} justifyContent='flex-end'>
                    <Grid item sx={{ mb: 2, textAlign: 'end' }} xs={12}>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Marquee gradient={false} speed='80'>
                                <Typography variant="hotelGardenName">HAIFU GARDEN HOTEL • 海福花園飯店 • </Typography>
                            </Marquee>
                        </AnimationOnScroll>
                    </Grid>
                    <Grid item>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            {/* <Typography sx={{ textAlign: 'right' }} variant="landingGarden">海福花園飯店</Typography> */}
                            {/* <div style={{ height: '40vh', width: '90vw' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyBKjkwOOnCjicw-yS8Mg9-NERB23OXL6VE", id: "df9dc46d4d8af819" }}
                                    defaultCenter={defaultProps.gardenCenter}
                                    defaultZoom={defaultProps.zoom}
                                >
                                    <AnyReactComponent
                                        lat={59.955413}
                                        lng={30.337844}
                                        text="My Marker"
                                    />
                                </GoogleMapReact>
                            </div> */}
                            {/* <div style={{ height: '80vh', width: '90vw' }}> */}
                                <div style={{ height: '40vh', width: '90vw' }} dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3631.5414005911834!2d118.38791321544089!3d24.466691384241184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3414a3582b4447a7%3A0x2442c8f60d04899b!2z5rW356aP6Iqx5ZyS6aOv5bqX!5e0!3m2!1sen!2stw!4v1662961136994!5m2!1sen!2stw" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' }} />
                            {/* </div> */}
                        </AnimationOnScroll>
                    </Grid>
                    {/* <Grid item sx={{ mx: 2 }} xs={12} md={6}>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <img src="image/haifu_hotel-min-long.jpg" className='hotel-img' alt="" />
                        </AnimationOnScroll>
                    </Grid> */}
                    <Grid item xs={12} sx={{ mx: 3, mt: 2 }}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            {/* <AnimationOnScroll animateIn="animate__fadeInUp" delay='50'>
                                <LocalPhoneOutlinedIcon sx={{ color: '#fff' }} />
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay='75'>
                                <FaxOutlinedIcon sx={{ color: '#fff' }} />
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay='100'>
                                <EmailOutlinedIcon sx={{ color: '#fff' }} />
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay='125'>
                                <img src={LineIcon} style={{
                                    width: '25px', height: 'auto', filter: 'grayscale(100%)'
                                }} />
                            </AnimationOnScroll> */}
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay='150'>
                                <Button sx={{ borderRadius: 12.5 }} variant="contained" onClick={() => navigate('/garden')}> 進入官網 </Button>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInUp" delay='175'>
                            <a href="https://www.booking-wise0.com.tw/haifu/hotel/reservation/reservation.php?hid=102" target='_blank'>
                                <Button sx={{ borderRadius: 12.5 }} variant="contained"> 線上訂房 </Button>
                            </a>
                            </AnimationOnScroll>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    );
}

export default Landing;
