import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import CardMedia from '@mui/material/CardMedia';
import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

function Embdded({ handleCloseModal }) {
    const [open, setOpen] = useState(true);
    const [loadCardMedia, setLoadCardMedia] = useState(false);

    const handleClose = () => {
        setOpen(false);
        handleCloseModal();
    }

    useEffect(() => {
        if (open) {
            setLoadCardMedia(true);
        } else {
            setLoadCardMedia(false);
        }
    }, [open]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex', // Ensures the Modal content uses flex layout
                    alignItems: 'center', // Vertically centers the Box
                    justifyContent: 'center', // Horizontally centers the Box
                    height: '100%', // Ensures the Modal is full height
                    backdropFilter: 'brightness(50%)', // Darkens the backdrop
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Aligns the Box to the center
                    justifyContent: 'center', // Aligns the Box to the center
                    width: { xs: '100vw', md: '70vw' }, // Ensures the container matches the CardMedia width on different screen sizes
                    height: { xs: '80vh', md: '80vh' }, // Ensures the container matches the CardMedia height on different screen sizes
                }}>
                    <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end', color: 'white' }}>
                        <CloseIcon sx={{ padding: '5px' }} />
                    </IconButton>
                    {loadCardMedia && <CardMedia component="iframe"
                        src="https://www.youtube.com/embed/uWxmiy9riyA?autoplay=1&controls=0"
                        sx={{
                            Height: "100 %",
                            aspectRatio: "16/9",
                        }}
                    />}
                </Box>
            </Modal>
        </div>
    );
}

export default function ButtonModalEmbed() {
    // State to control the visibility of the Embdded modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div>
            <Button onClick={handleOpenModal} sx={{
                position: 'absolute',
                right: 35,
                bottom: 60,
                zIndex: 300
            }}>
                <Box
                    component="img"
                    src="20240810_vid.gif"
                    alt="Open Modal"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
                        width: '160px',
                        height: '160px',
                        display: 'block',
                        borderRadius: '75%',
                        outlineOffset: '-4px'
                    }}
                ></Box>
            </Button>

            {isModalOpen && <Embdded handleCloseModal={handleCloseModal} />}
        </div>
    );
}
